<template>
  <div>
    <v-card>
      <v-card>
        <v-card-title class="align-start ">
          <v-spacer></v-spacer>
          <v-btn
            v-if="showAddMemberButton"
            color="primary"
            @click="dialog = true"
          >
            <v-icon>
              {{ icons.mdiPlusCircle }}
            </v-icon>
            <span class="ml-1">Add member </span>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-data-table
        :headers="headers"
        :items="members"
        item-key="full_name"
        class="table-rounded"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <div class="d-flex flex-column">
            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.user.name }}</span>
            <small>{{ item.role }}</small>
          </div>
        </template>
        <template #[`item.total_savings`]="{ item }">
          {{ `UGX ${(parseFloat(item.total_savings)).toLocaleString()}` }}
        </template>
        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="statusColor[item.status]"
            class="font-weight-medium"
          >
            {{ item.status }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      width="1024"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add a new member</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="mx-auto"
                >
                  <v-alert
                    v-model="snackbar"
                    outlined
                    type="error"
                    prominent
                    border="left"
                    dismissible
                  >
                    <v-list-item
                      v-for="(error, key) in errors"
                      :key="key"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ key }}</v-list-item-title>
                        <v-list-item-subtitle
                          v-for="(item, i) in error"
                          :key="i"
                        >
                          {{ item }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <VuePhoneNumberInput
                    v-model="member.phone"
                    clearable
                    :default-country-code="defaultCountryCode"
                    :only-countries="['UG']"
                    hide-details
                    label="Phone*"
                    :error="!validPhoneNumber"
                    @update="isPhoneNumberValid"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="member.name"
                      label="Name*"
                      :error-messages="errors"
                      outlined
                      clearable
                      dense
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="member.role"
                    dense
                    outlined
                    :items="[{ text: 'Member', value: 'member' }, { text: 'Admin', value: 'admin' }]"
                    label="Role"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              variant="text"
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              :disabled="(invalid || (!validPhoneNumber))"
              variant="text"
              :loading="loading"
              @click="saveGroupMember()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical, mdiPlusCircle } from '@mdi/js'
// eslint-disable-next-line import/extensions
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { parsePhoneNumber } from 'libphonenumber-js'
// eslint-disable-next-line object-curly-newline
import { required, digits } from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
export default {
  components: {
    VuePhoneNumberInput,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      active: 'primary',
      Professional: 'success',
      Inactive: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'NAME', value: 'name' },
        { text: 'PHONE', value: 'user.phone' },
        { text: 'DATE JOINED', value: 'created_at' },
        { text: 'TOTAL', value: 'total_savings' },
        { text: 'STATUS', value: 'status' },
      ],
      statusColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlusCircle,
      },
    }
  },
  props: {
    members: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    groupId: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      snackbar: false,
      errors: {},
      dialog: false,
      phone: '',
      validPhoneNumber: false,
      defaultCountryCode: 'UG',
      error: null,
      loading: false,
      member: {
        phone: '',
        name: '',
        role: 'member',
      },
    }
  },
  computed: {
    isAdmin() {
      if (this.group.membership) {
        return this.group.membership.role === 'admin'
      }

      return false
    },
    showAddMemberButton() {
      if (this.group.invitation_policy === 'any-member') {
        return true
      }
      if (this.isAdmin) {
        return true
      }

      return false
    },
  },
  methods: {
    /**
 * Check if the phone number is valid
 */
    isPhoneNumberValid(value) {
      // if input is valid
      if (value.isValid) {
        // check if phone number already exists
        this.validPhoneNumber = true
      } else {
        this.validPhoneNumber = false
      }
    },

    /**
   * Hits the api to save this group member
   */
    saveGroupMember() {
      this.loading = true

      // format phone number to start with +256
      const phoneNumber = parsePhoneNumber(this.member.phone, this.defaultCountryCode).formatInternational()
      this.member.phone = phoneNumber

      this.$http
        .post(`/groups/${this.groupId}/members`, this.member)
        .then(() => {
          this.$emit('membercreated', this.member)
          this.dialog = false
        })
        .finally(() => {
          this.loading = false
        })
        .catch(errors => {
          this.loading = false
          this.errors = errors.response.data.errors
          this.snackbar = true
          console.log(errors)
        })
    },
  },

}
</script>
