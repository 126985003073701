<template>
  <div>
    <v-card>
      <v-card v-if="isAdmin">
        <v-card-title class="align-start ">
          <v-spacer v-if="isAdmin"></v-spacer>
          <v-btn
            v-if="isAdmin"
            color="primary"
            @click="dialog = true"
          >
            <v-icon>
              {{ icons.mdiPlusCircle }}
            </v-icon>
            <span class="ml-1">Add Deposit </span>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-data-table
        :headers="headers"
        :items="transactions"
        item-key="full_name"
        class="table-rounded"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <div class="d-flex flex-column">
            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.user.name }}</span>
            <small>{{ item.role }}</small>
          </div>
        </template>
        <template #[`item.amount`]="{ item }">
          {{ `UGX ${(parseFloat(item.amount)).toLocaleString()}` }}
        </template>
        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="statusColor[item.status]"
            class="font-weight-medium"
          >
            {{ item.status }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      width="1024"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add a new Deposit</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="mx-auto"
                >
                  <v-alert
                    v-model="snackbar"
                    outlined
                    type="error"
                    prominent
                    border="left"
                    dismissible
                  >
                    Oops!! Something is wrong
                    <v-list-item
                      v-for="(error, key) in errors"
                      :key="key"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ key }}</v-list-item-title>
                        <v-list-item-subtitle
                          v-for="(item, i) in error"
                          :key="i"
                        >
                          {{ item }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="member"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="transaction.user_id"
                      dense
                      outlined
                      :items="members"
                      label="Member*"
                      item-text="user.name"
                      item-value="user_id"
                      :error-messages="errors"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="amount"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="transaction.amount"
                      label="Amount*"
                      outlined
                      clearable
                      dense
                      required
                      type="number"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              variant="text"
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              variant="text"
              :disabled="invalid"
              :loading="loading"
              @click="saveDeposit()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical, mdiPlusCircle } from '@mdi/js'
import { required, numeric } from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('numeric', {
  ...numeric,
  message: '{_field_} should be a valid number',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      successful: 'primary',
      successful1: 'success',
      Inactive: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'NAME', value: 'name' },
        { text: 'PHONE', value: 'user.phone' },
        { text: 'AMOUNT', value: 'amount' },
        { text: 'DATE PAID', value: 'created_at' },
        { text: 'STATUS', value: 'status' },

      ],
      statusColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlusCircle,
      },
    }
  },
  props: {
    transactions: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    members: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    groupId: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      snackbar: false,
      errors: {},
      dialog: false,
      phone: '',
      validPhoneNumber: false,
      defaultCountryCode: 'UG',
      error: null,
      loading: false,
      transaction: {
        user_id: '',
        amount: '',
      },
    }
  },
  computed: {
    isAdmin() {
      if (this.group.membership) {
        return this.group.membership.role === 'admin'
      }

      return false
    },
  },
  methods: {
    /**
 * Check if the phone number is valid
 */
    isPhoneNumberValid(value) {
      // if input is valid
      if (value.isValid) {
        // check if phone number already exists
        this.validPhoneNumber = true
      } else {
        this.validPhoneNumber = false
      }
    },

    /**
   * Hits the api to save this group member
   */
    saveDeposit() {
      this.loading = true

      this.$http
        .post(`/groups/${this.groupId}/transactions`, this.transaction)
        .then(() => {
          this.$emit('transactioncreated', this.transaction)
          this.dialog = false
        })
        .finally(() => {
          this.loading = false
        })
        .catch(errors => {
          this.loading = false
          this.errors = errors.response.data.errors
          this.snackbar = true
          console.log(errors)
        })
    },
  },

}
</script>
