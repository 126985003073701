<template>
  <v-row>
    <v-col
      cols="12"
      class="mx-auto"
    >
      <v-alert
        v-model="showAlert"
        outlined
        type="error"
        prominent
        border="left"
      >
        Oops!! Something is wrong
        <v-list-item
          v-for="(error, key) in errors"
          :key="key"
        >
          <v-list-item-content>
            <v-list-item-title>{{ key }}</v-list-item-title>
            <v-list-item-subtitle
              v-for="(item, i) in error"
              :key="i"
            >
              {{ item }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    errors: { type: Object, default: null },
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      showAlert: false,
    }
  },
  watch: {
    show(newValue) {
      this.showAlert = newValue
    },
  },
}
</script>

<style>

</style>
