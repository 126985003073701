<template>
  <div>
    <v-card>
      <v-card v-if="isAdmin">
        <v-card-title class="align-start ">
          <v-spacer></v-spacer>
          <v-btn
            v-if="isAdmin"
            color="primary"
            @click="showAddLoanModel()"
          >
            <v-icon>
              {{ icons.mdiPlusCircle }}
            </v-icon>
            <span class="ml-1">Add Loan </span>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-data-table
        :headers="headers"
        :items="loans"
        item-key="full_name"
        class="table-rounded"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <div class="d-flex flex-column">
            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.user.name }}</span>
          </div>
        </template>
        <template #[`item.total_savings`]="{ item }">
          {{ `UGX ${(parseFloat(item.total_savings)).toLocaleString()}` }}
        </template>
        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="statusColor[item.status]"
            class="font-weight-medium"
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="item.status == 'pending' && isAdmin"
            x-small
            color="primary"
            :loading="loading"
            @click="approveLoan(item.id)"
          >
            Approve
          </v-btn>
          <v-icon
            v-else
            @click="rowClick(item)"
          >
            {{ icons.mdiEyeOutline }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      width="1024"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-card>
          <v-card-title>
            <span
              v-if="editMode"
              class="text-h5"
            >Loan transaction</span>
            <span
              v-else
              class="text-h5"
            >Add a new loan</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <error-response-card
                :show="show_error"
                :errors="errors"
              ></error-response-card>
              <v-row class="py-0">
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="member"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="loan.user_id"
                      outlined
                      :items="members"
                      label="Member*"
                      item-text="user.name"
                      item-value="user_id"
                      dense
                      clearable
                      :disabled="editMode"
                      :error-messages="errors"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="py-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="amount"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="loan.amount"
                      label="Amount*"
                      outlined
                      clearable
                      required
                      type="number"
                      dense
                      :disabled="editMode"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="py-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="repayment amount"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="loan.repayment_amount"
                      label="Repayment amount*"
                      outlined
                      clearable
                      required
                      dense
                      :error-messages="errors"
                      type="number"
                      :disabled="editMode"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="py-0"
                >
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="start_date"
                        rules="required"
                      >
                        <v-text-field
                          v-model="loan.start_date"
                          label="When does the loan start?"
                          hint="MM/DD/YYYY format"
                          :aria-errormessage="errors"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          :error-messages="errors"
                          :disabled="editMode"
                          v-on="on"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="loan.start_date"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="py-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="loan_duration"
                    rules="required"
                  >
                    <v-text-field
                      v-model="loan.loan_duration"
                      label="Loan duration(months)*"
                      outlined
                      clearable
                      required
                      dense
                      :error-messages="errors"
                      type="number"
                      :disabled="editMode"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-textarea
                    v-model="loan.notes"
                    label="Notes"
                    auto-grow
                    outlined
                    clearable
                    dense
                    rows="3"
                    :disabled="editMode"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="editMode">
                <v-col cols="12">
                  <v-card>
                    <v-card-title class="align-start ">
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="isAdmin"
                        color="primary"
                        @click="dialogLoanRepayment = true"
                      >
                        <v-icon>
                          {{ icons.mdiPlusCircle }}
                        </v-icon>
                        <span class="ml-1">Add Loan payment </span>
                      </v-btn>
                    </v-card-title>
                    <v-data-table
                      :headers="loanRepaymentHeaders"
                      :items="loanRepayments"
                      item-key="full_name"
                      class="table-rounded"
                    >
                      <template #[`item.name`]="{ item }">
                        <div class="d-flex flex-column">
                          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.user.name
                          }}</span>
                        </div>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              variant="text"
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              v-if="!editMode"
              color="primary"
              variant="text"
              :loading="loading"
              :disabled="invalid"
              @click="saveGroupLoan()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
    <v-dialog
      v-model="dialogLoanRepayment"
      persistent
      width="500"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add a new loan repayment for this loan</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <error-response-card
                :show="loan_repayment_show_errors"
                :errors="errors"
              ></error-response-card>
              <v-row>
                <v-col
                  cols="12"
                  class="my-0 py-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="member"
                    rules="required"
                  >
                    <v-text-field
                      v-model="loanRepayment.amount"
                      label="Amount*"
                      outlined
                      clearable
                      required
                      dense
                      type="number"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <!-- <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="loan.start_date"
                      label="When does the loan start?"
                      hint="MM/DD/YYYY format"
                      :aria-errormessage="errors"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="loan.start_date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col> -->
                <v-col
                  cols="12"
                  class="my-0 py-0"
                >
                  <v-textarea
                    v-model="loanRepayment.notes"
                    label="Notes"
                    auto-grow
                    outlined
                    clearable
                    rows="3"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              variant="text"
              @click="dialogLoanRepayment = false"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              variant="text"
              :loading="loading"
              :disabled="invalid"
              @click="saveLoanRepayment()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline, mdiDotsVertical, mdiPlusCircle, mdiEyeOutline,
} from '@mdi/js'
import { required, numeric } from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import ErrorResponseCard from '@/components/ErrorResponseCard.vue'

setInteractionMode('eager')

extend('numeric', {
  ...numeric,
  message: '{_field_} should be a valid number',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
extend('greathan_amount', value => value >= this.loan.amount)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ErrorResponseCard,
  },
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      active: 'primary',
      Professional: 'success',
      Inactive: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'NAME', value: 'name' },
        { text: 'AMOUNT', value: 'amount' },
        { text: 'REPAYMENT AMOUNT', value: 'repayment_amount' },
        { text: 'BALANCE', value: 'balance' },
        { text: 'START DATE', value: 'start_date' },
        { text: 'REPAYMENT DATE', value: 'repayment_date' },
        { text: 'STATUS', value: 'status' },
        { text: 'NOTES', value: 'notes' },
        { text: 'ACTIONS', value: 'actions' },

      ],
      loanRepaymentHeaders: [
        { text: 'AMOUNT', value: 'amount' },
        { text: 'DATE CREATED', value: 'created_at' },
        { text: 'STATUS', value: 'status' },
        { text: 'NOTES', value: 'notes' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      statusColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlusCircle,
        mdiEyeOutline,
      },
      menu1: false,
      errors: {},
    }
  },
  props: {
    loans: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    members: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    groupId: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show_error: false,
      loan_repayment_show_errors: false,
      errors: {},
      dialog: false,
      phone: '',
      validPhoneNumber: false,
      defaultCountryCode: 'UG',
      error: null,
      loading: false,
      loan: {
        amount: '',
        user_id: '',
        group_id: this.groupId,
        start_date: '',
        repayment_amount: '',
        loan_duration: 0,
        notes: '',
      },
      loanRepayment: {
        amount: '',
        user_id: '',
        group_id: this.groupId,
        loan_id: '',
        notes: '',
      },
      editMode: false,
      dialogLoanRepayment: false,
      loanRepayments: [],
    }
  },
  computed: {
    isAdmin() {
      if (this.group.membership) {
        return this.group.membership.role === 'admin'
      }

      return false
    },
  },
  methods: {
    showAddLoanModel() {
      this.loan = {}
      this.loan.group_id = this.groupId
      this.editMode = false
      this.dialog = true
      this.loanRepayments = []
    },

    /**
 * Check if the phone number is valid
 */
    isPhoneNumberValid(value) {
      // if input is valid
      if (value.isValid) {
        // check if phone number already exists
        this.validPhoneNumber = true
      } else {
        this.validPhoneNumber = false
      }
    },

    /**
   * Hits the api to save this group member
   */
    saveGroupLoan() {
      this.loading = true
      this.show_error = false

      this.$http
        .post('/loans', this.loan)
        .then(() => {
          this.$emit('loancreated', this.loan)
          this.dialog = false
        })
        .finally(() => {
          this.loading = false
        })
        .catch(errors => {
          this.loading = false
          this.errors = errors.response.data.errors
          this.show_error = true
          console.log(errors)
        })
    },

    /**
   * Hits the api to approve a pending loan
   */
    approveLoan(loanId) {
      this.loading = true

      this.$http
        .post(`/loans/${loanId}/approve`)
        .then(() => {
          this.$emit('loancreated', this.loan)
        })
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
        .catch(err => {
          console.log(err)
        })
    },

    /**
   * Hits the api to record a loan repayment of a loan
   */
    saveLoanRepayment() {
      this.loading = true
      this.loan_repayment_show_errors = false

      this.$http
        .post('loan-repayments', this.loanRepayment)
        .then(() => {
          this.$emit('loancreated', this.loanRepayment)
          this.dialog = false
          this.dialogLoanRepayment = false
        })
        .finally(() => {
          this.loading = false
        })
        .catch(errors => {
          this.loading = false
          this.errors = errors.response.data.errors
          this.loan_repayment_show_errors = true
          console.log(errors)
        })
    },

    /**
     * Handle click of row of the loans table
     */
    rowClick(item) {
      this.loan = item
      this.fetchLoanDetails(item.id)
      this.loanRepayment.user_id = item.user_id
      this.loanRepayment.group_id = item.group_id
      this.loanRepayment.loan_id = item.id
      this.dialog = true
      this.editMode = true
    },
    fetchLoanDetails(loanId) {
      this.loading = true
      this.$http
        .get(`/loans/${loanId}`)
        .then(res => {
          this.loan = res.data.data
          this.loanRepayments = res.data.data.repayments
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
  },

}
</script>
