var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[(_vm.isAdmin)?_c('v-card',[_c('v-card-title',{staticClass:"align-start "},[_c('v-spacer'),(_vm.isAdmin)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showAddLoanModel()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlusCircle)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("Add Loan ")])],1):_vm._e()],1)],1):_vm._e(),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.loans,"item-key":"full_name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.user.name))])])]}},{key:"item.total_savings",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("UGX " + ((parseFloat(item.total_savings)).toLocaleString())))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.status]}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status == 'pending' && _vm.isAdmin)?_c('v-btn',{attrs:{"x-small":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.approveLoan(item.id)}}},[_vm._v(" Approve ")]):_c('v-icon',{on:{"click":function($event){return _vm.rowClick(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","width":"1024"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[(_vm.editMode)?_c('span',{staticClass:"text-h5"},[_vm._v("Loan transaction")]):_c('span',{staticClass:"text-h5"},[_vm._v("Add a new loan")])]),_c('v-card-text',[_c('v-container',[_c('error-response-card',{attrs:{"show":_vm.show_error,"errors":_vm.errors}}),_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"member","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.members,"label":"Member*","item-text":"user.name","item-value":"user_id","dense":"","clearable":"","disabled":_vm.editMode,"error-messages":errors},model:{value:(_vm.loan.user_id),callback:function ($$v) {_vm.$set(_vm.loan, "user_id", $$v)},expression:"loan.user_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Amount*","outlined":"","clearable":"","required":"","type":"number","dense":"","disabled":_vm.editMode,"error-messages":errors},model:{value:(_vm.loan.amount),callback:function ($$v) {_vm.$set(_vm.loan, "amount", $$v)},expression:"loan.amount"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"repayment amount","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Repayment amount*","outlined":"","clearable":"","required":"","dense":"","error-messages":errors,"type":"number","disabled":_vm.editMode},model:{value:(_vm.loan.repayment_amount),callback:function ($$v) {_vm.$set(_vm.loan, "repayment_amount", $$v)},expression:"loan.repayment_amount"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"When does the loan start?","hint":"MM/DD/YYYY format","aria-errormessage":errors,"readonly":"","outlined":"","dense":"","error-messages":errors,"disabled":_vm.editMode},model:{value:(_vm.loan.start_date),callback:function ($$v) {_vm.$set(_vm.loan, "start_date", $$v)},expression:"loan.start_date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.loan.start_date),callback:function ($$v) {_vm.$set(_vm.loan, "start_date", $$v)},expression:"loan.start_date"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"loan_duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Loan duration(months)*","outlined":"","clearable":"","required":"","dense":"","error-messages":errors,"type":"number","disabled":_vm.editMode},model:{value:(_vm.loan.loan_duration),callback:function ($$v) {_vm.$set(_vm.loan, "loan_duration", $$v)},expression:"loan.loan_duration"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Notes","auto-grow":"","outlined":"","clearable":"","dense":"","rows":"3","disabled":_vm.editMode},model:{value:(_vm.loan.notes),callback:function ($$v) {_vm.$set(_vm.loan, "notes", $$v)},expression:"loan.notes"}})],1)],1),(_vm.editMode)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"align-start "},[_c('v-spacer'),(_vm.isAdmin)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogLoanRepayment = true}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlusCircle)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("Add Loan payment ")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.loanRepaymentHeaders,"items":_vm.loanRepayments,"item-key":"full_name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.user.name))])])]}}],null,true)})],1)],1)],1):_vm._e()],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","variant":"text"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),(!_vm.editMode)?_c('v-btn',{attrs:{"color":"primary","variant":"text","loading":_vm.loading,"disabled":invalid},on:{"click":function($event){return _vm.saveGroupLoan()}}},[_vm._v(" Save ")]):_vm._e()],1)],1)]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialogLoanRepayment),callback:function ($$v) {_vm.dialogLoanRepayment=$$v},expression:"dialogLoanRepayment"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Add a new loan repayment for this loan")])]),_c('v-card-text',[_c('v-container',[_c('error-response-card',{attrs:{"show":_vm.loan_repayment_show_errors,"errors":_vm.errors}}),_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"member","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Amount*","outlined":"","clearable":"","required":"","dense":"","type":"number","error-messages":errors},model:{value:(_vm.loanRepayment.amount),callback:function ($$v) {_vm.$set(_vm.loanRepayment, "amount", $$v)},expression:"loanRepayment.amount"}})]}}],null,true)})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Notes","auto-grow":"","outlined":"","clearable":"","rows":"3","dense":""},model:{value:(_vm.loanRepayment.notes),callback:function ($$v) {_vm.$set(_vm.loanRepayment, "notes", $$v)},expression:"loanRepayment.notes"}})],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","variant":"text"},on:{"click":function($event){_vm.dialogLoanRepayment = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","variant":"text","loading":_vm.loading,"disabled":invalid},on:{"click":function($event){return _vm.saveLoanRepayment()}}},[_vm._v(" Save ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }