var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[(_vm.isAdmin)?_c('v-card',[_c('v-card-title',{staticClass:"align-start "},[(_vm.isAdmin)?_c('v-spacer'):_vm._e(),(_vm.isAdmin)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlusCircle)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("Add Deposit ")])],1):_vm._e()],1)],1):_vm._e(),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.transactions,"item-key":"full_name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.user.name))]),_c('small',[_vm._v(_vm._s(item.role))])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("UGX " + ((parseFloat(item.amount)).toLocaleString())))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.status]}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","width":"1024"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Add a new Deposit")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"outlined":"","type":"error","prominent":"","border":"left","dismissible":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Oops!! Something is wrong "),_vm._l((_vm.errors),function(error,key){return _c('v-list-item',{key:key},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(key))]),_vm._l((error),function(item,i){return _c('v-list-item-subtitle',{key:i},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1)})],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"member","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"dense":"","outlined":"","items":_vm.members,"label":"Member*","item-text":"user.name","item-value":"user_id","error-messages":errors},model:{value:(_vm.transaction.user_id),callback:function ($$v) {_vm.$set(_vm.transaction, "user_id", $$v)},expression:"transaction.user_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Amount*","outlined":"","clearable":"","dense":"","required":"","type":"number","error-messages":errors},model:{value:(_vm.transaction.amount),callback:function ($$v) {_vm.$set(_vm.transaction, "amount", $$v)},expression:"transaction.amount"}})]}}],null,true)})],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","variant":"text"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","variant":"text","disabled":invalid,"loading":_vm.loading},on:{"click":function($event){return _vm.saveDeposit()}}},[_vm._v(" Save ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }