<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
        </v-card>
      </v-col>
    </v-row>
    <v-card>
      <v-img
        src="@/assets/images/pages/card-basic-influencer.png"
        height="250"
      />
      <v-card-title>
        {{ group.name }}
      </v-card-title>
      <v-card-text>
        {{ group.description }}
      </v-card-text>
      <v-card-text>
        <v-row v-if="group.wallet">
          <v-col
            cols="6"
            color="primary"
            md="3"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiAccountMultiple }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Member count
              </p>
              <h3 class="text-xl font-weight-semibold">
                {{ group.member_count }}
              </h3>
            </div>
          </v-col>
          <v-col
            cols="6"
            color="primary"
            md="3"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="secondary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiBankMinus }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Total of loans given out
              </p>
              <h3 class="text-xl font-weight-semibold">
                {{ `UGX ${(parseFloat(group.total_loans)).toLocaleString()}` }}
              </h3>
            </div>
          </v-col>
          <v-col
            cols="6"
            color="primary"
            md="3"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiWalletOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Total interest earned from loans
              </p>
              <h3 class="text-xl font-weight-semibold">
                {{ `UGX ${(parseFloat(group.total_interest)).toLocaleString()}` }}
              </h3>
            </div>
          </v-col>
          <v-col
            cols="6"
            color="primary"
            md="3"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiBankRemove }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Total unpaid loans
              </p>
              <h3 class="text-xl font-weight-semibold">
                {{ `UGX ${(parseFloat(group.total_unpaid_loans)).toLocaleString()}` }}
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-col v-if="group.wallet" cols="12" class="mb-4">
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="4"
          >
            <statistics-card-vertical
              change=""
              color="success"
              :icon="icons.mdiPoll"
              :statistics="'UGX ' + Intl.NumberFormat().format(group.total_savings)"
              stat-title="Total Savings"
              subtitle="Total savings for all group members"
            ></statistics-card-vertical>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <statistics-card-vertical
              change=""
              color="secondary"
              :icon="icons.mdiCurrencyUsd"
              :statistics="'UGX ' + Intl.NumberFormat().format(group.wallet.balance)"
              stat-title="Available balance"
              subtitle="Total amount available for loans and withdraws"
            ></statistics-card-vertical>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <statistics-card-vertical
              change=""
              color="primary"
              :icon="icons.mdiLabelVariantOutline"
              :statistics="'UGX ' + Intl.NumberFormat().format(group.total_earned)"
              stat-title="Total earned"
              subtitle="Total earned from loan interest and late charges"
            ></statistics-card-vertical>
          </v-col>
        </v-row>
      </v-col>
      <v-divider></v-divider>
      <!-- tabs -->
      <v-progress-linear
        :active="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <group-details-members-list
            :members="members"
            :group-id="id"
            :group="group"
            @membercreated="memberCreated"
          ></group-details-members-list>
        </v-tab-item>

        <v-tab-item>
          <group-details-transactions-list
            :transactions="transactions"
            :members="members"
            :group="group"
            :group-id="id"
            @transactioncreated="transactionCreated"
          ></group-details-transactions-list>
        </v-tab-item>

        <v-tab-item>
          <group-details-loans-list
            :loans="loans"
            :members="members"
            :group="group"
            :group-id="id"
            @loancreated="loanCreated"
          ></group-details-loans-list>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiBank, mdiWalletOutline, mdiAccountMultiple,
  mdiAccountCreditCard, mdiBankMinus, mdiBankRemove,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

import GroupDetailsMembersList from './GroupDetailsMembersList.vue'
import GroupDetailsTransactionsList from './GroupDetailsTransactionsList.vue'
import GroupDetailsLoansList from './GroupDetailsLoansList.vue'
import StatisticsCardVertical from '@/components/statistics-card/GroupStatisticsCard.vue'

export default {
  components: {
    GroupDetailsMembersList,
    GroupDetailsTransactionsList,
    GroupDetailsLoansList,
    StatisticsCardVertical,
  },
  data() {
    return {
      id: this.$route.params.id,
      members: [],
      transactions: [],
      loans: [],
      loading: false,
      group: {},
    }
  },
  created() {
    this.fetchGroupDetails()
    this.fetchGroupMembers()
    this.fetchGroupTransactions()
    this.fetchGroupLoans()
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Members', icon: mdiAccountOutline },
      { title: 'Transactions', icon: mdiLockOpenOutline },
      { title: 'Loans', icon: mdiBank },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiWalletOutline,
        mdiAccountMultiple,
        mdiAccountCreditCard,
        mdiBankMinus,
        mdiBankRemove,
      },
    }
  },
  methods: {
    memberCreated() {
      this.fetchGroupDetails()
      this.fetchGroupMembers()
    },
    transactionCreated() {
      this.fetchGroupDetails()
      this.fetchGroupTransactions()
    },
    loanCreated() {
      this.fetchGroupDetails()
      this.fetchGroupLoans()
    },
    fetchGroupMembers() {
      this.loading = true

      // const { page, itemsPerPage } = this.options
      const page = 0
      const itemsPerPage = 100
      this.$http
        .get(`/groups/${this.id}/members?page=${page}&per_page=${itemsPerPage}`)
        .then(res => {
          this.members = res.data.data
          this.pagination = res.data.meta
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    fetchGroupTransactions() {
      this.loading = true

      // const { page, itemsPerPage } = this.options
      const page = 0
      const itemsPerPage = 100
      this.$http
        .get(`/groups/${this.id}/transactions?page=${page}&per_page=${itemsPerPage}`)
        .then(res => {
          this.transactions = res.data.data
          this.pagination = res.data.meta
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    fetchGroupLoans() {
      this.loading = true

      // const { page, itemsPerPage } = this.options
      const page = 1
      const itemsPerPage = 100
      this.$http
        .get(`/groups/${this.id}/loans?page=${page}&per_page=${itemsPerPage}`)
        .then(res => {
          this.loans = res.data.data
          this.pagination = res.data.meta
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    fetchGroupDetails() {
      this.loading = true
      this.$http
        .get(`/groups/${this.id}`)
        .then(res => {
          this.group = res.data.data
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style></style>
